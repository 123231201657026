<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Commission Config</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Commission Config</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="CommissionConfig">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-header">
              <h3 class="card-title" v-if="!is_edit">Create</h3>
              <h3 class="card-title" v-if="is_edit">Edit</h3>
              <div class="card-tools">
                <router-link class="btn btn-info" :to="{name: 'CommissionConfigList'}"><i class="fas fa-list"></i> List
                </router-link>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group required">
                    <label class="control-label">Name</label>
                    <input type="text" id="name" name="name" v-bind:class="{'has-error' : errors.has('name')}" v-model="value.name" class="form-control name" placeholder="Name">
                    <div class="help text-danger" v-show="errors.has('name')">
                      {{ errors.first("name") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Description</label>
                    <input type="text" id="description" name="description" v-bind:class="{'has-error' : errors.has('description')}" v-model="value.description" class="form-control description" placeholder="Description">
                    <div class="help text-danger" v-show="errors.has('description')">
                      {{ errors.first("description") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Commission Plan</label>
                    <select id="commission_plan_id" v-model="value.commission_plan_id" v-bind:class="{'has-error' : errors.has('commission_plan_id')}" class="form-control commission_plan_id">
                      <option v-for="commissionPlan in allCommissionPlan" :value="commissionPlan.id" :key='commissionPlan.id'>{{ commissionPlan.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('commission_plan_id')">
                      {{ errors.first("commission_plan_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Status</label>
                    <select class="form-control" style="width: 100%;" name="status" v-bind:class="{'has-error' : errors.has('status')}" v-model="value.status" id="status">
                      <option :value="1">Active</option>
                      <option :value="0">Inactive</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('status')">
                      {{ errors.first("status") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Activation Startdate</label>
                    <date-picker type="date" class="col-12" input-class="form-control" :input-attr="{name:'activation_start'}" v-model="value.activation_start" lang="en" format="YYYY-MM-DD" id="activation_start" value-type="YYYY-MM-DD"></date-picker>
                    <div class="help text-danger" v-show="errors.has('activation_start')">
                      {{ errors.first("activation_start") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Activation Enddate</label>
                    <date-picker type="date" class="col-12" input-class="form-control" :input-attr="{name:'activation_end'}" v-model="value.activation_end" lang="en" format="YYYY-MM-DD" id="activation_end" value-type="YYYY-MM-DD"></date-picker>
                    <div class="help text-danger" v-show="errors.has('activation_end')">
                      {{ errors.first("activation_end") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Priority</label>
                    <input type="number" id="priority" name="priority" v-bind:class="{'has-error' : errors.has('priority')}" v-validate="'required'" v-model="value.priority" class="form-control priority" placeholder="Priority">
                    <div class="help text-danger" v-show="errors.has('priority')">
                      {{ errors.first("priority") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Commission type</label>
                    <select class="form-control" style="width: 100%;" name="commission_type" v-model="value.commission_type" id="commission_type">
                      <option value="Percentage">Percentage</option>
                      <option value="Flat">Flat</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('commission_type')">
                      {{ errors.first("commission_type") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Commission</label>
                    <input type="number" id="commission" name="commission" v-model="value.commission" class="form-control commission" placeholder="Commission">
                    <div class="help text-danger" v-show="errors.has('commission')">
                      {{ errors.first("commission") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Max commission amount</label>
                    <input type="number" id="max_commission_amount" name="max_commission_amount" v-model="value.max_commission_amount" class="form-control maxCommissionAmount" placeholder="Max commission amount">
                    <div class="help text-danger" v-show="errors.has('max_commission_amount')">
                      {{ errors.first("max_commission_amount") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Applicable Period</label>
                    <select class="form-control" style="width: 100%;" name="applicable_period" v-model="value.applicable_period" id="applicable_period">
                      <option value="During-Sale">During-Sale</option>
                      <option value="After-Commission-Period">After-Commission-Period</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('applicable_period')">
                      {{ errors.first("applicable_period") }}
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="control-label">Previous Tier Id</label>
                    <select id="prev_tier_id" v-model="value.prev_tier_id" v-bind:class="{'has-error' : errors.has('prev_tier_id')}" class="form-control prev_tier_id">
                      <option v-for="commissionPlan in allCommissionPlan" :value="commissionPlan.id" :key='commissionPlan.id'>{{ commissionPlan.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('prev_tier_id')">
                      {{ errors.first("prev_tier_id") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Match all conditions</label>
                    <select class="form-control" style="width: 100%;" name="follow_all_conds" v-model="value.follow_all_conds" id="follow_all_conds">
                      <option :value="1">Yes</option>
                      <option :value="0">No</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('follow_all_conds')">
                      {{ errors.first("follow_all_conds") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Disbursement date config</label>
                    <input type="text" id="disb_date_conf" name="disb_date_conf" v-model="value.disb_date_conf" class="form-control disb_date_conf" placeholder="Disbursement date configuration">
                    <div class="help text-danger" v-show="errors.has('disb_date_conf')">
                      {{ errors.first("disb_date_conf") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Next disbursement date</label>
                    <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'next_disbursement_date'}" v-model="value.next_disbursement_date" lang="en" format="YYYY-MM-DD hh:mm:ss" id="next_disbursement_date" value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                    <div class="help text-danger" v-show="errors.has('next_disbursement_date')">
                      {{ errors.first("next_disbursement_date") }}
                    </div>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">Split type</label>
                    <select class="form-control" style="width: 100%;" name="split_type" v-model="value.split_type" id="split_type">
                      <option value="Horizontal">Horizontal</option>
                      <option value="Vertical">Vertical</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('split_type')">
                      {{ errors.first("split_type") }}
                    </div>
                  </div>
                  <div class="offset-0 col-12">
                    <h4 class="site-heading my-3"><span>Additional Properties</span></h4>
                    <div class="form-row align-items-end" v-for="(key_val, i) in value.additional_properties_converted" :key="i">
                      <div class=" col-md-10">
                        <div class="row">
                          <div class="col">
                            <div class="form-group col-md-12">
                              <label class="control-label">Key</label>
                              <input type="text" name="key_val.key" v-model="key_val.key" class="form-control key_val.key" placeholder="Key">
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group col-md-12">
                              <label class="control-label">Value</label>
                              <input type="text" name="key_val.value" v-model="key_val.value" class="form-control key_val.value" placeholder="Value">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <button type="button" class="btn btn-danger btn-sm float-right" @click="delete_key_value(i)"><i class="fa fa-trash"></i> Remove</button>
                      </div>
                    </div>
                    <button type="button" @click="add_key_value()" class="btn btn-success btn-sm"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                  <div class="form-group required">
                    <label class="control-label">App-Id</label>
                    <select id="app_id" v-model="value.app_id" v-bind:class="{'has-error' : errors.has('app_id')}" class="form-control app_id">
                      <option v-for="application in allApplications" :value="application.app_id" :key='application.app_id'>{{ application.name }}</option>
                    </select>
                    <div class="help text-danger" v-show="errors.has('app_id')">
                      {{ errors.first("app_id") }}
                    </div>
                  </div>
                  <div class="form-group ">
                    <label class="control-label">Migrate after</label>
                    <date-picker type="datetime" class="col-12" input-class="form-control" :input-attr="{name:'migrate_after'}" v-model="value.migrate_after" lang="en" format="YYYY-MM-DD hh:mm:ss" id="migrate_after" value-type="YYYY-MM-DD hh:mm:ss"></date-picker>
                    <div class="help text-danger" v-show="errors.has('migrate_after')">
                      {{ errors.first("migrate_after") }}
                    </div>
                  </div>
                  <div>
                    <h4 class="site-heading my-3"><span>Commission Conditions</span></h4>
                    <div class="form-row align-items-end" v-for="(commission_condition_item, index) in value.commission_condition" :key="index">
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Status</label>
                        <select class="form-control" style="width: 100%;" name="commission_condition_item.status" v-bind:class="{'has-error' : errors.has('commission_condition_item.status')}" v-model="commission_condition_item.status" id="commission_condition_item.status">
                          <option :value="1">Active</option>
                          <option :value="0">Inactive</option>
                        </select>
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.status')">
                          {{ errors.first("commission_condition_item.status") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Name</label>
                        <select id="app_id" v-model="commission_condition_item.name" class="form-control app_id">
                          <option v-for="conditionName in allConditionName" :value="conditionName.name" :key='conditionName.id'>{{ conditionName.name }}</option>
                        </select>
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.name')">
                          {{ errors.first("commission_condition_item.name") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Operator</label>
                        <select class="form-control" style="width: 100%;" name="commission_condition_item.op" v-bind:class="{'has-error' : errors.has('commission_condition_item.op')}" v-model="commission_condition_item.op" id="commission_condition_item.op">
                          <option value="=">=</option>
                          <option value="!=">!=</option>
                          <option value=">">&gt;</option>
                          <option value="<">&lt;</option>
                          <option value=">=">&gt;=</option>
                          <option value="<=">&lt;=</option>
                        </select>
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.op')">
                          {{ errors.first("commission_condition_item.op") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Value</label>
                        <input type="text" id="commission_condition_item.value" name="commission_condition_item.value" v-model="commission_condition_item.value" class="form-control commission_condition_item.value" placeholder="Value">
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.value')">
                          {{ errors.first("commission_condition_item.value") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="control-label">Operator 2</label>
                        <select class="form-control" style="width: 100%;" name="commission_condition_item.op2" v-bind:class="{'has-error' : errors.has('commission_condition_item.op2')}" v-model="commission_condition_item.op2" id="commission_condition_item.op2">
                          <option value="=">=</option>
                          <option value="!=">!=</option>
                          <option value=">">&gt;</option>
                          <option value="<">&lt;</option>
                          <option value=">=">&gt;=</option>
                          <option value="<=">&lt;=</option>
                        </select>
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.op2')">
                          {{ errors.first("commission_condition_item.op2") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label class="control-label">Value 2</label>
                        <input type="text" id="commission_condition_item.value2" name="commission_condition_item.value2" v-model="commission_condition_item.value2" class="form-control commission_condition_item.value2" placeholder="value2">
                        <div class="help text-danger" v-show="errors.has('commission_condition_item.value2')">
                          {{ errors.first("commission_condition_item.value2") }}
                        </div>
                      </div>
                      <div class="form-group col-md-12 border-bottom pb-3">
                        <button type="button" class="btn btn-danger btn-sm float-right" @click="deleteCommission_condition(index)"><i class="fa fa-trash"></i> Remove
                        </button>
                      </div>
                    </div>
                    <button type="button" @click="addCommission_condition" class="btn btn-success"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                  <div>
                    <h4 class="site-heading my-3"><span>Split Commission(s)</span></h4>
                    <div class="form-row align-items-end" v-for="(split_commission_item, index) in value.commission_split" :key="index">
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Status</label>
                        <select class="form-control" style="width: 100%;" name="split_commission_item.status" v-bind:class="{'has-error' : errors.has('split_commission_item.status')}" v-model="split_commission_item.status" id="split_commission_item.status">
                          <option :value="1">Active</option>
                          <option :value="0">Inactive</option>
                        </select>
                        <div class="help text-danger" v-show="errors.has('split_commission_item.status')">
                          {{ errors.first("split_commission_item.status") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Order</label>
                        <input type="text" id="split_commission_item.order" name="split_commission_item.order" v-model="split_commission_item.order" class="form-control split_commission_item.order" placeholder="Order">
                        <div class="help text-danger" v-show="errors.has('split_commission_item.order')">
                          {{ errors.first("split_commission_item.order") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Commission</label>
                        <input type="text" id="split_commission_item.split_commission" name="split_commission_item.split_commission" v-model="split_commission_item.split_commission" class="form-control split_commission_item.split_commission" placeholder="Commission">
                        <div class="help text-danger" v-show="errors.has('split_commission_item.split_commission')">
                          {{ errors.first("split_commission_item.split_commission") }}
                        </div>
                      </div>
                      <div class="form-group col-md-6 required">
                        <label class="control-label">Split with</label>
                        <input type="text" id="split_commission_item.split_with" name="split_commission_item.split_with" v-model="split_commission_item.split_with" class="form-control split_commission_item.split_with" placeholder="Split with">
                        <div class="help text-danger" v-show="errors.has('split_commission_item.split_with')">
                          {{ errors.first("split_commission_item.split_with") }}
                        </div>
                      </div>
                      <input type="hidden" name="split_commission_item.created_by" v-model="split_commission_item.created_by"/>
                      <div class="form-group col-md-12 border-bottom pb-3">
                        <button type="button" class="btn btn-danger float-right" @click="deleteSplit_commission(index)"><i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                    <button type="button" @click="addSplit_commission" class="btn btn-success"><i class="fa fa-plus"></i> New
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
              <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import { CBSCommissionApi, CBSCommonApi } from '@/services/api';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'CommissionConfigForm',
  components: {
    DatePicker
  },
  computed: {
    ...mapGetters(['profile'])
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},
    value: {
      commission_condition: [],
      commission_split: [],
      additional_properties: {},
      additional_properties_converted: []
    },
    allCommissionPlan: [],
    allApplications: [],
    allCommissionConfig: [],
    allConditionName: [],
    pre: {}
  }),
  methods: {
    handleSubmission () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true
          //key value convert
          this.value.additional_properties = {}
          this.value.additional_properties_converted.forEach((value, key) => {
            this.value.additional_properties[value.key] = value.value
          })

          if (this.is_edit) {
            this.value.updated_by = this.profile.id
            CBSCommissionApi.updateCommissionConfig(this.params.id, this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$router.push({ name: 'CommissionConfigList' })
                  this.$toaster.success(response.message)
                } else {
                  this.$toaster.error(response.message)
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          } else {

            this.value.organization_ref_id = this.profile.organization_ref_id
            this.value.user_ref_id = this.profile.user_ref_id
            this.value.role_ref_id = this.profile.role_ref_id
            this.value.created_by = this.profile.id
            CBSCommissionApi.storeCommissionConfig(this.value)
              .then((response) => {
                if (response.code == 200) {
                  this.$toaster.success(response.message);
                  this.$router.push({ name: 'CommissionConfigList' });
                } else {
                  this.$toaster.error(response.message);
                }
              })
              .catch((error) => {
                this.$setErrorsFromResponse(error.response.data)
              }).finally(() => {
                this.is_loading = false
              })
          }
        }
      });
    },
    add_key_value: function () {
      this.value.additional_properties_converted.push({});
    },
    delete_key_value: function (index) {
      this.value.additional_properties_converted.splice(index, 1)
    },
    addCommission_condition: function () {
      this.value.commission_condition.push({})
    },
    addSplit_commission: function () {
      this.value.commission_split.push({'created_by':this.profile.id})
    },

    deleteCommission_condition: function (index) {
      this.value.commission_condition.splice(index, 1)
    },
    deleteSplit_commission: function (index) {
      this.value.commission_split.splice(index, 1)
    },
    getDetail: function (id) {
      CBSCommissionApi.showCommissionConfig(id)
        .then((item) => {
          this.value = item

          let obj = []
          _.forEach(this.value.additional_properties, function (value, key) {
            obj.push({ 'key': key, 'value': value })
          })
          this.$set(this.value, 'additional_properties_converted', obj)
          this.$delete(this.value, "additional_properties")
        })
        .catch((error) => {
          this.$toaster.error(error.response.data.message)
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getApplications: function () {
      CBSCommonApi.getAllApplications().then((response) => {
        this.allApplications = response;
      });
    },
    getCommissionPlan: function () {
      CBSCommonApi.getAllCommissionPlan().then((response) => {
        this.allCommissionPlan = response
      })
    },
    getCommissionConfig: function () {
      CBSCommonApi.getAllCommissionConfig().then((response) => {
        this.allCommissionConfig = response
      })
    },
    getConditionName: function () {
      CBSCommonApi.getAllConditionName().then((response) => {
        this.allConditionName = response
      })
    },
    async initialize () {
      this.is_loading = true
      this.params = this.$route.params

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id)
      }
      await this.getCommissionPlan()
      await this.getApplications()
      await this.getCommissionConfig()
      await this.getConditionName()
      this.is_loading = false

    }
  },
  mounted () {
    this.initialize()
  }
}
</script>
